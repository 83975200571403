@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai+Looped:wght@400;500&display=swap');
@import "~bootstrap/scss/bootstrap";

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: #f0f0f0;
    color: #2b2b2b;
    font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

a {
    text-decoration: none;
    color: unset;
}